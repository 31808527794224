<template>
    <div class="content__container">
        <p class="promo promo_blocks2">{{ "s4p9_text_1" | localize }}</p>
        <form @submit.prevent="submitHandler">
          <div v-for="(acc, k) in accounts" :key="k">
              <div class="promo__container" v-if="clientCount > 1">
                <p class="promo">{{ "account" | localize }} {{ k + 1 }}</p>
                <button type="button" v-if="accounts.length > 1" class="delete" @click="deleteAccount(k)"></button>
              </div>
              <div :class="'scroll_to_' + k"></div>

              <div class="form__group">
                <v-select
                    v-model.lazy="acc.bet"
                    @blur="check(k, 'bet')"
                    :class="{ invalid: checks[k].bet && !acc.bet }"
                    item-text="name"
                    item-value="value"
                    :items="getList('s4p9_select_1_value_', 5)"
                    :label="'s4p9_label_1' | localize"
                    :placeholder="'select' | localize"
                    :menu-props="{ offsetY: true }"
                    append-icon="icon-arrow-down"
                    outlined
                ></v-select>

                <v-text-field
                    v-model.lazy="acc.price"
                    @blur="check(k, 'price')"
                    class="input_number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    @keypress="onKeyPressHandler"
                    @keyup="onKeyUpArrayHandler(acc.price, k, 'price')"
                    :class="{ invalid: checks[k].price && !acc.price }"
                    :label="'s4p9_label_2' | localize"
                    :placeholder="'s4p9_placeholder_1' | localize"
                    append-icon="icon-₪"
                    outlined
                ></v-text-field>
              </div>

              <div class="form__group">
                <v-select
                    v-if="members > 1"
                    v-model.lazy="acc.client"
                    @blur="check(k, 'client')"
                    :class="{ invalid: checks[k].client && !acc.client }"
                    :items="clients"
                    item-text="name"
                    item-value="value"
                    :label="'s3p7_label_3' | localize"
                    :placeholder="'select' | localize"
                    :menu-props="{ offsetY: true }"
                    append-icon="icon-arrow-down"
                    outlined
                ></v-select>
              </div>
          </div>

          <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
          </div>

          <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler">
              {{ "form_continue" | localize }}
          </button>

          <button type="button" class="button button__form button__form_empty" @click="addAccount(scheme)">
              + {{ "form_add_account" | localize }}
          </button>
        </form>
    </div>
</template>

<script>
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

export default {
  name: 'About_incomes',
  mixins: [clientMixin, commonMixin, numberMixin],
  props: { 
    service: { type: String, required: true },
    nextlink: { type: String, required: true }
  },
  data: () => ({ scheme: { bet: null, price: null } }),
  created () {
    const data = this.$cookies.get(this.service) || {}

    // Получатели и данные
    this.members = data.saved_clients ? data.saved_clients.length : 1
    this.clients = data.saved_clients || []
    this.accounts = data.incomes || []
    if (this.members > 1) this.scheme.client = null

    this.prepareScheme(this.scheme)
    this.success = !this.checkError()
  },
  mounted () {
    this.accounts = this.changePrices(this.accounts, { price: 0 })
  },
  methods: {
    submitHandler () {
      const errorKey = this.checkError(true)
      if (errorKey !== false) {
        this.$scrollTo('.scroll_to_' + errorKey, 300, { offset: -150 })
        this.success = false
        return
      }

      this.success = true
      const data = this.$cookies.get(this.service) || {}
      data.incomes = this.changePrices(this.accounts, { price: 0 }, true)
      this.$cookies.set(this.service, data)
      this.saveClientData('client_incomes', this.nextlink, this.service)
    }
  }
}
</script>